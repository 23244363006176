import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import ButtonBar from '../../components/button-bar/button-bar.component';
import Cfa from '../../components/cfa/cfa.component';
import Euro from '../../components/euro/euro.component';
import KeyValueRow from '../../components/key-value-row/key-value-row.component';
import ScreenPanel from '../../components/screen-panel/screen-panel.component';
import ScreenTitle from '../../components/screen-title/screen-title.component';
import { Server } from '../../components/server/server.component';
import Transaction from '../../components/transaction/transaction.component';
import AuditInfo from '../../components/audit-info/audit-info.component';

const AccountDetailsScreen = ({ match: { params: { id } } }) => {
    const { accounts } = useContext(Server);
    const history = useHistory();
    const [data, setData] = useState({});
    const [error, setError] = useState();

    useEffect(() => {
        accounts.get(id, d => {
            if (d.transactions) {
                d.transactions.reverse();
            }
            setData(d);
        }, setError);
    }, [accounts, id]);

    if (!data) {
        return null;
    }

    return (
        <ScreenPanel>
            <ScreenTitle label="Account" />
            <KeyValueRow k="Nom">{data.name}</KeyValueRow>
            <KeyValueRow k="Utilisateur">{data.userId}</KeyValueRow>
            <KeyValueRow k="Solde du compte"><Euro euro={data.balance} /> / <Cfa euro={data.balance} /></KeyValueRow>
            <AuditInfo data={data} />
            <Transaction header />
            {
                data.transactions && data.transactions.map(t => <Transaction transaction={t}/>)
            }
            <ButtonBar>
                <Button variant="secondary" onClick={() => history.replace("/accounts")}>Annuler</Button>
                <div style={{ display: "none" }}>
                    <Button onClick={() => history.push(`/accounts/${id}/edit`)}>Modifier</Button>
                </div>
            </ButtonBar>
        </ScreenPanel>
    )
}

export default AccountDetailsScreen
