import React from 'react'

const fmtEur = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });
export const toEuro = cfa => cfa / 655.957;
export const toCfa = euro => euro * 655.957;

const Euro = ({ euro, cfa }) => {
    if (euro) {
        return <span>{fmtEur.format(euro)}</span>;
    }
    if (cfa) {
        return <span>{fmtEur.format(toEuro(cfa))}</span>;
    }
    return <span>{fmtEur.format(0)}</span>;
}

export default Euro
