import React, { useContext, useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import Cfa from '../cfa/cfa.component'
import Euro, { toEuro } from '../euro/euro.component'
import KeyValueRow from '../key-value-row/key-value-row.component'
import { Server } from '../server/server.component'

import styles from './article.module.css';

const fmtPct = new Intl.NumberFormat('fr-FR', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });

const Article = ({ data }) => {
    const { articles, image } = useContext(Server);
    const [item, setItem] = useState();

    useEffect(() => {
        articles.get(data.id, setItem, console.error);
    }, [articles, data.id])

    if (!item) {
        return null;
    }
    const profit = toEuro(item.priceSelling) - item.pricePurchasing;
    return (
        <div className={styles.panel}>
            <div>
                {
                    item.image && <img alt="" src={image(item.barcode)} className={styles.image} />
                }
            </div>
            <div>
                <ReactMarkdown children={item.text} />
                <KeyValueRow k="Code à barre">{item.barcode}</KeyValueRow>
                <KeyValueRow k="Prix d'achat"><Euro euro={item.pricePurchasing} /> / <Cfa euro={item.pricePurchasing} /></KeyValueRow>
                <KeyValueRow k="Prix ​​de vente"><Cfa cfa={item.priceSelling} /> / <Euro cfa={item.priceSelling} /></KeyValueRow>
                <KeyValueRow k="Profit"><Euro euro={profit} /> / <Cfa euro={profit} /> / {fmtPct.format(profit / item.pricePurchasing)}
                </KeyValueRow>
            </div>
        </div>
    )
}

export default Article
