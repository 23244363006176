/** @format */

import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import ButtonBar from '../button-bar/button-bar.component'
import KeyValueRow from '../key-value-row/key-value-row.component'
import { Server } from '../server/server.component'
import TransferList from '../transfer-list/transfer-list.component'
import { notify } from 'react-notify-toast'
import { useHistory } from 'react-router-dom'

import styles from './transfer-receive.module.css'
import Cfa from '../cfa/cfa.component'
import Euro from '../euro/euro.component'

const TransferReceive = () => {
  const { articles, transfers, receptions } = useContext(Server)
  const [started, setStarted] = useState([])
  const [selected, setSelected] = useState()
  const [articleItems, setArticleItems] = useState()
  const [sourceItems, setSourceItems] = useState([])
  const [items, setItems] = useState([])
  const [saved, setSaved] = useState()
  const history = useHistory()

  const buildSourceItemns = (articleItems, selected, saved) => {
    if (articleItems && selected) {
      var map = {}
      if (selected.items) {
        selected.items.forEach(i => (map[i.articleId] = i.count))
      }
      if (saved) {
        saved.items.forEach(i => {
          map[i.articleId] -= i.count
        })
        console.log('saved', saved)
        setItems(
          saved.items.map(i => ({
            ...i,
            id: i.articleId,
            barcode: articleItems[i.articleId].barcode,
            name: articleItems[i.articleId].name,
          }))
        )
      } else {
        setItems([])
      }
      const l = []
      Object.keys(map).forEach(articleId => {
        const art = articleItems[articleId]
        if (art) {
          l.push({
            id: articleId,
            barcode: art.barcode,
            name: art.name,
            count: map[articleId],
          })
        } else {
          console.error('article not found for articleId', articleId)
        }
      })
      setSourceItems(l)
    }
  }

  useEffect(() => {
    transfers.byStatus('STARTED', setStarted, console.log)
  }, [transfers])

  useEffect(() => {
    articles.query(
      null,
      data => {
        const m = {}
        data.forEach(art => (m[art.id] = art))
        setArticleItems(m)
      },
      console.log
    )
  }, [articles])

  useEffect(() => {
    buildSourceItemns(articleItems, selected, saved)
  }, [articleItems, selected, saved])

  useEffect(() => {
    if (selected) {
      receptions.byTransfer(selected.id, setSaved, () => {
        console.log('received error, clearing saved')
        setSaved(null)
      })
    }
  }, [selected, receptions])

  const load = box => {
    transfers.get(
      box.id,
      r => {
        setSelected(r)
      },
      console.log
    )
  }

  const save = () => {
    if (saved) {
      receptions.update(
        saved.id,
        {
          transferId: selected.id,
          items: items.map(i => ({ articleId: i.id, count: i.count })),
        },
        data => {
          setSaved(data)
          notify.show('enregistré', 'success', 3000)
        },
        console.error
      )
    } else {
      receptions.create(
        {
          transferId: selected.id,
          items: items.map(i => ({ articleId: i.id, count: i.count })),
        },
        data => {
          setSaved(data)
          notify.show('enregistré', 'success', 3000)
        },
        console.error
      )
    }
  }

  const terminate = () => {
    if (saved) {
      receptions.updateAndTerminate(
        saved.id,
        {
          transferId: selected.id,
          items: items.map(i => ({ articleId: i.id, count: i.count })),
        },
        () => {
          notify.show('reçu', 'success', 3000)
          history.push('/')
        },
        console.error
      )
    } else {
      receptions.createAndTerminate(
        {
          transferId: selected.id,
          items: items.map(i => ({ articleId: i.id, count: i.count })),
        },
        () => {
          notify.show('reçu', 'success', 3000)
          history.push('/')
        },
        console.error
      )
    }
  }

  const print = () => {
    history.push(`/transfers/${selected.id}`)
  }

  return (
    <>
      <div>
        <div>Paquets en route</div>
        {started &&
          started.map(p => (
            <div className={styles.started} onClick={() => load(p)}>
              <span>{p.name}</span>
              <span>{p.itemCount}</span>
            </div>
          ))}
      </div>
      {selected && (
        <div>
          <KeyValueRow k='Nom'>{selected.name}</KeyValueRow>
          <KeyValueRow k='Prix de transfert'>
            <Euro euro={selected.price} /> / <Cfa euro={selected.price} />
          </KeyValueRow>
          <TransferList sourceTitle='Dans le carton / à verifier' targetTitle='Verifié' sourceItems={sourceItems} value={items} onChange={setItems} />
          <ButtonBar>
            <Button variant='secondary' onClick={print}>
              Imprimer
            </Button>
            <Button variant='secondary' onClick={save}>
              Sauvegarder
            </Button>
            <Button onClick={terminate}>Terminer</Button>
          </ButtonBar>
        </div>
      )}
    </>
  )
}

export default TransferReceive
