import React from 'react'

const fmtCFA = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF', useGrouping: true });

const Cfa = ({ euro, cfa }) => {
    if (euro) {
        return <span>{fmtCFA.format(euro * 655.957)}</span>;
    }
    if (cfa) {
        return <span>{fmtCFA.format(cfa)}</span>;
    }
    return <span>{fmtCFA.format(0)}</span>;
}

export default Cfa;
