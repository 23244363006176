import React, { useContext } from 'react'
import { useHistory } from 'react-router';
import Cfa from '../cfa/cfa.component';
import Euro from '../euro/euro.component';
import { Search } from '../search/search.component';
import { Server } from '../server/server.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'

import styles from './article-info.module.css';

const ArticleInfo = ({ article, header, index, sort, onSort }) => {
    const { image } = useContext(Server);
    const { setCurrent } = useContext(Search);
    const history = useHistory();

    const headerIcon = colName => {
        if (colName !== sort.column) {
            return <FontAwesomeIcon icon={faSort}/>;
        }
        return sort.direction !== 'asc' ? <FontAwesomeIcon icon={faSortUp}/> : <FontAwesomeIcon icon={faSortDown}/> ;
    }
    const doSort = colName => {
        if (colName === sort.column) {
            onSort({...sort, direction: sort.direction === 'asc' ? 'desc' : 'asc'});
        } else {
            onSort({...sort, column: colName});
        }
    }

    const select = () => {
        setCurrent('articles', index);
        history.push(`/articles/${article.id}`);
    };

    if (header) {
        return <div className={styles.header}>
            <div className={styles.articleInfo}>
                <span>Image</span>
                <span onClick={() => doSort('name')}>Nom {headerIcon('name')}</span>
                <span className={styles.prices} >
                    <span onClick={() => doSort('pricePurchasing')}>Prix d'achat {headerIcon('pricePurchasing')}</span>
                    <span onClick={() => doSort('priceSelling')}>Prix ​​de vente {headerIcon('priceSelling')}</span>
                </span>
            </div>
        </div>;
    }

    return (
        <div className={styles.articleInfo} onClick={select}>
            <span>{article?.image && <img alt="" src={image(article?.barcode)} className={styles.image} />}</span>
            <span>{article.name}</span>
            <span className={styles.prices} >
                <Euro euro={article.pricePurchasing} />
                <Cfa cfa={article.priceSelling} />
            </span>
        </div>
    )
}

export default ArticleInfo;
