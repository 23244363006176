/** @format */

import { useContext, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import ReactMarkdown from 'react-markdown'
import Euro from '../../components/euro/euro.component'
import KeyValueRow from '../../components/key-value-row/key-value-row.component'
import ScreenPanel from '../../components/screen-panel/screen-panel.component'
import { Server } from '../../components/server/server.component'
import { notify } from 'react-notify-toast'
import { useHistory } from 'react-router'

const ArticleLookupScreen = ({
  match: {
    params: { id },
  },
}) => {
  const { articles } = useContext(Server)
  const [data, setData] = useState()
  const [look, setLook] = useState()
  const history = useHistory()

  useEffect(() => {
    articles.get(id, setData, console.error)
  }, [articles, id])

  return (
    <ScreenPanel>
      <div>
        <div>Existant</div>
        <KeyValueRow k='Nom'>{data?.name}</KeyValueRow>
        <KeyValueRow k="Prix d'achat">
          <Euro euro={data?.pricePurchasing} />
        </KeyValueRow>
        <ReactMarkdown children={data?.text} />
      </div>
      <Button onClick={() => articles.lookup(id, 'dm', setLook, console.error)}>Recherche DM</Button>
      <Button onClick={() => articles.lookup(id, 'Rossmann', setLook, console.error)}>Recherche Rossmann</Button>
      {look && (
        <>
          <div>
            <div>DM</div>
            <KeyValueRow k='Nom'>{look?.name}</KeyValueRow>
            <KeyValueRow k="Prix d'achat">
              <Euro euro={look?.pricePurchasing} />
            </KeyValueRow>
            <ReactMarkdown children={look?.text} />
          </div>
          <Button
            onClick={() => {
              data.name = look.name
              data.pricePurchasing = look.pricePurchasing
              data.text = look.text
              articles.update(
                id,
                data,
                response => {
                  notify.show('Mise à jour', 'success', 3000)
                  history.push('/articles/' + response.id)
                },
                () => {}
              )
            }}>
            Remplace
          </Button>
        </>
      )}
    </ScreenPanel>
  )
}

export default ArticleLookupScreen
