import React, { useContext, useEffect, useState } from 'react';
import { Button, Dropdown, DropdownButton, Modal } from 'react-bootstrap';
import { Server } from '../../components/server/server.component'
import CFAInput from '../cfa-input/cfa-input.component';
import Cfa from '../cfa/cfa.component';
import EuroInput from '../euro-input/euro-input.component';
import StringInput from '../string-input/string-input.component';
import TextInput from '../text-input/text-input.component';

import styles from './sale-item.module.css';

const SaleItem = ({ canEdit, item, header, onChange, onDelete }) => {
    const { articles } = useContext(Server);
    const [article, setArticle] = useState();
    const [openedArticle, setOpenedArticle] = useState(false);
    const [openedItem, setOpenedItem] = useState(false);
    const [data, setData] = useState({ barcode: item?.barcode });
    const [newCount, setNewCount] = useState(item?.count);

    useEffect(() => {
        if (item?.articleId) {
            articles.get(item.articleId, setArticle, () => { });
        }
    }, [articles, item])

    if (header) {
        return (
            <div className={styles.header}>
                <div className={styles.saleItem}>
                    <span>Code à barre</span>
                    <span>Nom</span>
                    <span>Prix ​​unique</span>
                    <span>Nombre</span>
                    <span>Prix total</span>
                    <span></span>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className={styles.saleItem}>
                <span>{item.barcode}</span>
                <span>{article?.name}</span>
                <Cfa cfa={item.itemPrice} />
                <span>{item.count}</span>
                <span>{item.itemPrice ? <Cfa cfa={item.itemPrice * item.count} /> : null}</span>
                {canEdit &&
                    <span>
                        <DropdownButton>
                            {
                                !article && <Dropdown.Item onSelect={() => setOpenedArticle(true)} eventKey="article">Artikel anlegen</Dropdown.Item>
                            }
                            <Dropdown.Item onSelect={() => setOpenedItem(true)} eventKey="edit">Bearbeiten</Dropdown.Item>
                            <Dropdown.Item onSelect={() => onDelete(item)} eventKey="delete">Löschen</Dropdown.Item>
                        </DropdownButton>
                    </span>
                }
            </div>
            <Modal show={openedArticle}>
                <Modal.Header>
                    <Modal.Title>Neuer Artikel</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <StringInput label="Nom" value={data.name} onChange={text => setData({ ...data, name: text })} />
                    <StringInput label="Code à barre" value={data.barcode} onChange={text => setData({ ...data, barcode: text })} />
                    <EuroInput label="Prix d'achat" value={data.pricePurchasing} onChange={text => setData({ ...data, pricePurchasing: text })} />
                    <CFAInput label="Prix ​​de vente" value={data.priceSelling} onChange={text => setData({ ...data, priceSelling: text })} />
                    <TextInput label="Texte" value={data.text} onChange={text => setData({ ...data, text: text })} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setOpenedArticle(false)}>Annuler</Button>
                    <Button variant="primary" onClick={() => {
                        setOpenedArticle(false);
                        articles.create(data, response => {
                            setArticle(response);
                            item.articleId = response.id;
                            item.itemPrice = response.pricePurchasing;
                            onChange(item);
                        }, () => { });

                    }}>Sauvegarder</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={openedItem}>
                <Modal.Header>
                    <Modal.Title>Bearbeiten</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <StringInput label="Nombre" value={newCount} onChange={setNewCount} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setOpenedItem(false)}>Annuler</Button>
                    <Button variant="primary" onClick={() => {
                        setOpenedItem(false);
                        onChange({ ...item, count: newCount });
                    }}>Sauvegarder</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SaleItem
